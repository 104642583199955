'use client';
import { Flex, Text, Box, Skeleton } from '@chakra-ui/react';
import { IntakeProgramPhases, WelkinPrograms } from '@innerwell/dtos';
import { useRouter, useSearchParams } from 'next/navigation';

import { ExtendedLayout } from '@/components/Home/ExtendedLayout';
import { IntakeLayout } from '@/components/Home/IntakeLayout';
import { InnerwellLogo } from '@/components/Images';
import { WelcomeModal } from '@/components/Modals/WelcomeModal';

import { motion } from 'framer-motion';
import useMyPatient from '@/hooks/react-query/useMyPatient';
import { useAppVersionQuery } from '@/services/env/hooks/use-app-version-query';
import { ShowRN } from '@/components/RN/ShowRN';

export const HomepageClientPage = () => {
   const { replace } = useRouter();
   const searchParams = useSearchParams();
   const showWelcomeModal = searchParams.get('show_welcome_modal') === '1';
   const { data: patient } = useMyPatient();
   const { data: appVersion } = useAppVersionQuery();

   const handleWelcomeModalClose = () => {
      replace('/');
   };

   return (
      <Flex
         maxW={{ lg: 'container.md' }}
         flexDir="column"
         mx="auto"
         pt={{ base: 'none', lg: '3.75rem' }}
      >
         {showWelcomeModal ? (
            <WelcomeModal onCloseComplete={handleWelcomeModalClose} />
         ) : null}

         <Flex flexDir="column" mb={9} mt={7}>
            <InnerwellLogo fill="white" display={{ lg: 'none' }} />
            <ShowRN>
               <Text fontSize="xxs" opacity={0.8}>
                  v{appVersion}
               </Text>
            </ShowRN>
         </Flex>

         <Box pt={5} pb={{ base: 12, lg: 8 }}>
            {patient?.currentProgram ? (
               <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0.3 }}
               >
                  {patient.currentProgram.programName ===
                     WelkinPrograms.Intake &&
                  patient.currentProgram.currentPhase.name !==
                     IntakeProgramPhases.ExtendedLayoutUnlocked ? (
                     <IntakeLayout
                        patient={patient}
                        initialPhase={patient.currentProgram.currentPhase.name}
                     />
                  ) : (
                     <ExtendedLayout />
                  )}
               </motion.div>
            ) : (
               <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0.3 }}
               >
                  <Skeleton h="10rem" rounded="lg" />
               </motion.div>
            )}
         </Box>
      </Flex>
   );
};
