import { Skeleton, useDisclosure } from '@chakra-ui/react';
import { getErrorMessage } from '@innerwell/utils';
import { useRouter } from 'next/navigation';

import useMyPatient from '@/hooks/react-query/useMyPatient';
import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';

import { EligibilityFormIntake } from '../Insurance/EligibilityFormIntake';
import BoxedModal from '../Modals/BoxedModal';
import { usePatientInKetamineProgram } from '@/services/patient/hooks/use-patient-in-ketamine-program';
import { Card } from '../Cards/components/Card';

const HomeCheckInsuranceEligibilityStep = ({
   onSuccess,
}: {
   onSuccess?: () => void;
}) => {
   const { data: patient, isLoading: isLoadingPatient } = useMyPatient();
   const isInKetamineProgram = usePatientInKetamineProgram();

   const eligibilityModal = useDisclosure();

   const { push } = useRouter();
   const { toastError } = useThemedToast();

   const handleSwitch = async () => {
      try {
         const response = await webApiClient.programSwitch.getSwitchData();

         const info = response.body;
         if (!info.therapyPlan) {
            toastError('No selected therapy plan found');
            return;
         }

         push(
            `/purchase/plan/${info.switchCategoryId}?${new URLSearchParams({
               therapyPlan: info.therapyPlan,
               ref: '/',
            }).toString()}`,
         );
      } catch (e) {
         toastError(
            `Failed to get insurance program switch info: ${getErrorMessage(e)}`,
         );
      }
   };

   return (
      <Skeleton isLoaded={!isLoadingPatient} borderRadius="md">
         <Card minH="none">
            <Card.Title>Confirm your insurance coverage</Card.Title>
            <Card.Text>
               Please give us the details of your insurance coverage so that we
               can confirm your eligibility and verify that you are covered.
            </Card.Text>

            <Card.ButtonGroup>
               <Card.Button
                  onClick={eligibilityModal.onOpen}
                  isDisabled={!patient}
               >
                  Check Eligibility
               </Card.Button>

               {isInKetamineProgram && patient?.isMedicalIntakeRefused ? (
                  <Card.ButtonLink href="/support" variant="link">
                     Contact Member Support
                  </Card.ButtonLink>
               ) : (
                  <Card.Button variant="link" onClick={handleSwitch}>
                     Continue Without Insurance
                  </Card.Button>
               )}
            </Card.ButtonGroup>
         </Card>

         {eligibilityModal.isOpen ? (
            <BoxedModal
               title="Insurance Eligibility"
               {...eligibilityModal}
               bg="background.secondary"
            >
               <EligibilityFormIntake
                  onDone={() => {
                     eligibilityModal.onClose();
                     onSuccess?.();
                  }}
               />
            </BoxedModal>
         ) : null}
      </Skeleton>
   );
};

export default HomeCheckInsuranceEligibilityStep;
