import { type AdsCounterResponseDto, CdtAdsOriginEnum } from '@innerwell/dtos';
import {
   type QueryClient,
   useMutation,
   type UseMutationOptions,
   useQueryClient,
} from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import adsLocalStorage from '@/utils/adsLocalStorage';
import { queryKeys } from '@/types/query-keys';

type MutationProps = UseMutationOptions<
   AdsCounterResponseDto,
   Error,
   void,
   unknown
> & {
   nextAdsCount?: number;
};

const invalidateQueries = (queryClient: QueryClient) => {
   queryClient.invalidateQueries({ queryKey: queryKeys.adsCounter });
   queryClient.invalidateQueries({ queryKey: queryKeys.dosageConfirmations });
};

export const useUpdateMedicalAdsCounter = ({
   nextAdsCount,
   ...props
}: MutationProps) => {
   const queryClient = useQueryClient();

   return useMutation({
      mutationFn: async () => {
         const response = await webApiClient.adsCounter.addAdsValue({
            body: {
               index: nextAdsCount ?? adsLocalStorage.getDosingNumber(),
               origin: CdtAdsOriginEnum.OutsidePlatform,
            },
         });

         return response.body;
      },
      onSuccess: (...args) => {
         const res = args[0];
         adsLocalStorage.setDosingNumber(res.count);

         invalidateQueries(queryClient);

         props.onSuccess?.(...args);
      },
      ...props,
   });
};
