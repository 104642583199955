import { Box, Flex, type FlexProps, Text } from '@chakra-ui/react';
import { type ReactNode } from 'react';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { InfoDialog } from '../Dialog/InfoDialog';
import { Icon } from '../Icon';

type Props = {
   text: string | React.ReactNode;
   additionalInfoText?: string | ReactNode;
   showSupportButton?: boolean;
} & FlexProps;

export const ErrorCard = ({
   text,
   additionalInfoText,
   showSupportButton = true,
   ...flexProps
}: Props) => {
   return (
      <Flex
         bg="white"
         color="background.tertiary"
         maxW="490px"
         borderRadius="12px"
         boxShadow="0px 0px 20px rgba(0, 0, 0, 0.16)"
         p={4}
         py={5}
         pos="relative"
         my={4}
         {...flexProps}
      >
         <Icon
            name="warning-circled"
            color="accent.orange"
            boxSize={8}
            mr={3}
         />

         <Box>
            <Box fontSize="xs" fontWeight={600} lineHeight={1.3} opacity={0.8}>
               {text}
            </Box>
            {additionalInfoText ? (
               <InfoDialog mt={3} mb={2} mr={{ base: 4, lg: 0 }} maxW="350px">
                  <Text fontSize="xxs">{additionalInfoText}</Text>
               </InfoDialog>
            ) : null}
            {showSupportButton ? (
               <NextLinkButton mt={4} href="/support" size="xs">
                  Contact support
               </NextLinkButton>
            ) : null}
         </Box>
      </Flex>
   );
};
