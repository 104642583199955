import { type AppointmentReservation } from '@innerwell/dtos';
import { Card } from './components/Card';
import {
   chakra,
   Flex,
   Text,
   useBoolean,
   type FlexProps,
} from '@chakra-ui/react';
import { formatDateNicely } from '@/utils/formatting';
import { DateTime } from 'luxon';
import { Icon } from '../Icon';
import { useCreateAppointment } from '@/hooks/react-query/useCreateAppointment';
import useThemedToast from '@/hooks/useThemedToast';
import { getErrorMessage } from '@innerwell/utils';
import BoxedSandModal from '../Modals/BoxedSandModal';
import { ButtonLink } from './components/components/ButtonLink';
import { generateSchedulingLink } from '@/utils';
import { useRef } from 'react';
import useMyPatient from '@/hooks/react-query/useMyPatient';
import { useAppointmentReservation } from '@innerwell/ui';

const locationId = 512119;

export const ConfirmAppointmentReservationCard = ({
   reservation,
   onSuccess,
   showScheduleButton = false,
   ...rest
}: {
   reservation: AppointmentReservation;
   onSuccess?: () => void;
   showScheduleButton?: boolean;
} & FlexProps) => {
   const { data: patient } = useMyPatient();
   const onSuccessRef = useRef(onSuccess);
   const { clinician, slotEndDatetime, slotStartDatetime } = reservation;

   const { toastError, toastSuccess } = useThemedToast();
   const { mutate: createAppointment, isPending: isCreatingAppointment } =
      useCreateAppointment();
   const [isModalOpen, { on: openModal, off: closeModal }] = useBoolean(false);
   const { remove: removeAppointmentReservation } = useAppointmentReservation();

   const handleConfirmAppointment = async () => {
      createAppointment(
         {
            locationId,
            staffId: clinician.timetapId,
            templateName: reservation.appointmentTemplate,
            staff: {
               startDateUtc: DateTime.fromISO(slotStartDatetime).toMillis(),
               endDateUtc: DateTime.fromISO(slotEndDatetime).toMillis(),
            },
         },
         {
            onSuccess: () => {
               onSuccessRef.current?.();
               toastSuccess('Appointment confirmed!');
               removeAppointmentReservation();
            },
            onError: (error) => {
               const errorMessage = getErrorMessage(error);

               if (errorMessage.includes('slot')) {
                  openModal();
               } else {
                  toastError(errorMessage);
               }
            },
         },
      );
   };

   const chooseAnotherTimeUrl = generateSchedulingLink({
      encounterName: reservation.appointmentTemplate,
      locationId,
      staffId: reservation.clinician.timetapId,
      date: DateTime.fromISO(reservation.slotStartDatetime).toISODate(),
      forceFullUrl: true,
   });

   return (
      <>
         <BoxedSandModal
            isOpen={isModalOpen}
            onClose={closeModal}
            closeOnEsc
            closeOnOverlayClick
         >
            <Flex
               direction="column"
               gap={4}
               w="full"
               py={3}
               textAlign="left"
               alignItems="flex-start"
            >
               <Text size="leadText" textAlign="left">
                  Appointment slot not available
               </Text>

               <Text>
                  Unfortunately, the appointment slot is no longer available.
                  Please choose another time.
               </Text>

               <ButtonLink mt={3} href={chooseAnotherTimeUrl}>
                  Choose another time
               </ButtonLink>
            </Flex>
         </BoxedSandModal>
         <Card minH="none" {...rest}>
            <Card.Title>Your appointment reservation</Card.Title>
            <Card.Text>
               You have reserved an appointment with{' '}
               <chakra.span fontWeight={500}>
                  {reservation.clinician.name.trim()}
               </chakra.span>
               .
               <Flex
                  as="span"
                  alignItems="center"
                  gap={1}
                  mt={4}
                  fontWeight={500}
                  color="text.secondary"
                  opacity={0.8}
               >
                  <Icon name="clock" boxSize={4} />
                  {formatDateNicely(reservation.slotStartDatetime)} at{' '}
                  {DateTime.fromISO(reservation.slotStartDatetime).toFormat(
                     'h:mm',
                  )}{' '}
                  -{' '}
                  {DateTime.fromISO(reservation.slotEndDatetime).toFormat(
                     'h:mm a',
                  )}
               </Flex>
            </Card.Text>
            <Card.Image
               src={
                  reservation.clinician.avatarUrl ??
                  '/images/placeholder-clinician.png'
               }
               alt="Clinician"
               borderRadius="50%"
               imagePosition="center"
               boxSize={24}
            />
            {showScheduleButton ? (
               <Card.ButtonGroup mt={5}>
                  <Card.Button
                     isLoading={isCreatingAppointment}
                     onClick={handleConfirmAppointment}
                  >
                     Confirm Appointment
                  </Card.Button>
                  <Card.ButtonLink
                     href={chooseAnotherTimeUrl}
                     variant="link"
                     isDisabled={isCreatingAppointment}
                  >
                     Choose another time
                  </Card.ButtonLink>
               </Card.ButtonGroup>
            ) : (
               <Card.InfoText>
                  {patient?.insurance
                     ? 'You will be able to confirm your appointment after you finish onboarding and we check your eligibility'
                     : 'You will be able to confirm your appointment after you finish onboarding'}
               </Card.InfoText>
            )}
         </Card>
      </>
   );
};
