'use client';

import { useState } from 'react';
import { Button, Text } from '@chakra-ui/react';
import { shortenCharacters } from '@innerwell/utils';

type ExpandableTextProps = {
   children: string;
   maxChars: number;
};

export function ExpandableText({ children, maxChars }: ExpandableTextProps) {
   const [isExpanded, setIsExpanded] = useState(false);

   const isTextTooLong = children.length > maxChars;

   const transformedText = !isTextTooLong
      ? children
      : !isExpanded
        ? shortenCharacters(children, maxChars, '...')
        : children;

   return (
      <Text>
         {transformedText}

         {isTextTooLong ? (
            <Button
               display="inline-block"
               px={0}
               variant="unstyled"
               size="xs"
               minH="0"
               onClick={() => {
                  setIsExpanded(!isExpanded);
               }}
               ml={isExpanded ? 2 : 0}
            >
               {isExpanded ? 'Show less' : 'Show more'}
            </Button>
         ) : null}
      </Text>
   );
}
