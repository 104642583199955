import {
   APPOINTMENT_RESERVATION_COOKIE_NAME,
   type AppointmentReservation,
   appointmentReservationSchema,
} from '@innerwell/dtos';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';

export const getDomainName = (hostName: string) => {
   const hostnameArray = hostName.split('.');
   const lastTwo = hostnameArray.slice(-2).join('.');
   return lastTwo;
};

export const useAppointmentReservation = () => {
   const [getCookie, setCookie, deleteCookie] = useCookies([
      APPOINTMENT_RESERVATION_COOKIE_NAME,
   ]);

   const cookie = getCookie[APPOINTMENT_RESERVATION_COOKIE_NAME] as unknown;

   const get = useMemo(() => {
      const parsedCookie = appointmentReservationSchema.safeParse(cookie);

      if (!parsedCookie.success) {
         return null;
      }

      const reservation = parsedCookie.data;

      const appointmentDay = DateTime.fromISO(
         reservation.slotStartDatetime,
      ).startOf('day');

      const todaysDate = DateTime.now().startOf('day');

      if (todaysDate > appointmentDay) {
         return null;
      }

      return parsedCookie.data;
   }, [cookie]);

   const set = useCallback(
      (reservation: AppointmentReservation) => {
         setCookie(APPOINTMENT_RESERVATION_COOKIE_NAME, reservation, {
            domain: `.${getDomainName(window.location.hostname)}`,
            path: '/',
         });
      },
      [setCookie],
   );

   const remove = useCallback(() => {
      deleteCookie(APPOINTMENT_RESERVATION_COOKIE_NAME, {
         domain: `.${getDomainName(window.location.hostname)}`,
         path: '/',
      });
   }, [deleteCookie]);

   return { get, set, remove };
};
