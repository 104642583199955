import {
   Flex,
   Badge,
   Text,
   type FlexProps,
   useBreakpointValue,
   Image,
} from '@chakra-ui/react';
import { Illustration } from '../Illustration';
import Link from 'next/link';
import { Icon } from '../Icon';

export const RecommendationCard = ({
   children,
   href,
   isExternal,
   imageSrc,
   size = 'large',
   tag,
   parentFlexProps,
   ...rest
}: Omit<FlexProps, 'children'> & {
   children: string;
   href: string;
   isExternal?: boolean;
   imageSrc: string;
   size?: 'small' | 'large';
   parentFlexProps?: FlexProps;
   tag?: string;
}) => {
   const cardWidth = useBreakpointValue({
      base: '10rem',
      md: size === 'large' ? '11.75rem' : '10rem',
   });

   const badgeSize = useBreakpointValue({
      base: 'sm',
      md: size === 'large' ? 'md' : 'sm',
   });

   return (
      <Flex
         as={Link}
         href={href}
         target={isExternal ? '_blank' : '_self'}
         {...parentFlexProps}
      >
         <Flex
            bg="background.secondary"
            borderRadius="xl"
            position="relative"
            border="2px solid"
            borderColor="border"
            overflow="hidden"
            transition="border-color 0.2s"
            pt={10}
            _hover={{ borderColor: { lg: 'card.inactive' } }}
            w={cardWidth}
            {...rest}
         >
            <Badge
               position="absolute"
               left={2}
               top={2}
               zIndex={3}
               bg="accent.peach"
               borderRadius="md"
               size={badgeSize}
            >
               {tag ?? 'Learn'}
            </Badge>
            <Flex flexDir="column" position="relative" flex={1}>
               <Flex
                  position="absolute"
                  w={{ base: '7.5rem', lg: '8.75rem' }}
                  height="4.5rem"
                  top={0}
                  left="50%"
                  transform="translateX(-50%) translateY(-20px)"
                  zIndex={0}
                  borderRadius="lg"
                  overflow="hidden"
               >
                  <Image
                     src={imageSrc}
                     alt={children}
                     objectFit="cover"
                     objectPosition="center"
                     boxSize="full"
                  />
               </Flex>
               <Flex flexDir="column" position="relative" zIndex={2} flex={1}>
                  <Illustration
                     src="/illustrations/recommendation-card-shape.svg"
                     w="full"
                  />
                  <Flex
                     flexDir="column"
                     paddingBlockStart={1}
                     paddingBlockEnd={3}
                     px={3}
                     color="background.primary"
                     bg="#FFF5EE"
                     gap={2.5}
                     flex={1}
                     justifyContent="space-between"
                  >
                     <Text fontWeight={600} lineHeight={1.3}>
                        {children}
                     </Text>

                     <Icon
                        color="background.primary"
                        name={isExternal ? 'external-link' : 'chevron-right'}
                        boxSize={4}
                        ml="auto"
                        fontWeight={100}
                     />
                  </Flex>
               </Flex>
            </Flex>
         </Flex>
      </Flex>
   );
};
