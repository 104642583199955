import {
   type EligibilityResponseDataDto,
   type GetEligibilityDto,
} from '@innerwell/dtos';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

export type GetEligibilityParams = GetEligibilityDto & {
   insuranceName: string;
};

type MutationProps = UseMutationOptions<
   EligibilityResponseDataDto,
   Error,
   GetEligibilityParams,
   unknown
>;

export const useGetInsuranceEligibility = (props: MutationProps) => {
   return useMutation({
      mutationFn: async (data: GetEligibilityParams) => {
         const response = await webApiClient.insurance.getEligibility({
            body: {
               ...data,
               insured: {
                  ...data.insured,
                  dob: data.insured.dob?.toISO(),
               },
               patient: data.patient
                  ? {
                       ...data.patient,
                       dob: data.patient.dob?.toISO(),
                    }
                  : undefined,
               serviceDate: data.serviceDate?.toISO(),
            },
         });

         return response.body;
      },
      ...props,
   });
};
