import { Divider, Flex, Skeleton, Text } from '@chakra-ui/react';

import { useNextProgramEncountersToSchedule } from '@/hooks/react-query/useNextProgramEncountersToSchedule';
import useUpcomingAppointments from '@/hooks/react-query/useUpcomingAppointments';

import { useUserAccess } from '@/components/Cards/PatientLocked/useUserAccess';

import { AppointmentBank } from '../AppointmentBank/AppointmentBank';

import { PatientLocked } from '../Cards/PatientLocked/PatientLocked';
import ScheduleNextAppointmentCard from '../Cards/ScheduleNextAppointmentCard';
import { DividerWithText } from '../Dividers/DividerWithText';
import { SkeletonWithLoader } from '../Loaders/SkeletonWithLoader';
import { UpcomingAppointments } from '../Swipers/UpcomingAppointments';
import { PatientDischargedCard } from '../Cards/PatientDischargedCard';
import useMyPatient from '@/hooks/react-query/useMyPatient';
import { InsuranceStatusCard } from '../Cards/InsuranceStatusCard';
import { usePatientProgram } from '@/contexts/patient-program-context';
import { IntakeProgramPhases, SharedProgramPhases } from '@innerwell/dtos';
import { motion } from 'framer-motion';
import { getStaggerVariants } from '@/utils/animation-utils';
import { usePatientInKetamineProgram } from '@/services/patient/hooks/use-patient-in-ketamine-program';
import { InterestedInKetamine } from '../Cards/InterestedInKetamine';
import { MissedAppointmentPaymentCompletedCard } from '../Cards/MissedAppointmentPaymentCompletedCard';
import { StartKetamineExperienceCard } from '../Cards/StartKetamineExperienceCard';
import { SimpleMoodTracking } from '../MoodTracking/SimpleMoodTracking';
import { useTodayMoodTracking } from '@/hooks/react-query/useTodayMoodTracking';
import { WeeklyTrackAccordion } from '../MoodTracking/WeeklyTrackAccordion';
import { HorizontalCard } from '../HorizontalCard/HorizontalCard';
import { useRouter } from 'next/navigation';
import { useAppointmentCanBeScheduled } from '@/hooks/useAppointmentCanBeScheduled';
import { useScheduling } from '@/contexts/scheduling-context';
import { generateSchedulingLink } from '@/utils';

const staggerVariants = getStaggerVariants({
   staggerChildren: 0.2,
});

const appointmentBankSectionId = 'appointment-bank';

export const ExtendedLayout = () => {
   const { push } = useRouter();
   const { data: patient } = useMyPatient();
   const {
      programPhase: { phase },
   } = usePatientProgram();
   const currentProgramName = patient?.currentProgram?.programName;
   const isInKetamineProgram = usePatientInKetamineProgram(currentProgramName);

   const { isLocked, statuses } = useUserAccess();

   const { data: moodTrackingData } = useTodayMoodTracking();

   const { appointments: upcomingAppointments, status: appointmentsStatus } =
      useUpcomingAppointments();

   const { nextAppointments, isLoading: isLoadingNextAppointment } =
      useNextProgramEncountersToSchedule();

   const nextAppointment = nextAppointments.at(0);

   const {
      online: { location },
      isLocationsLoading,
   } = useScheduling();
   const appointmentCanBeScheduled =
      useAppointmentCanBeScheduled() && !nextAppointment?.error;

   return (
      <Flex
         as={motion.div}
         flexDir="column"
         gap={{ base: 4, lg: 6 }}
         variants={staggerVariants.container}
         initial="hidden"
         animate="show"
      >
         {phase === SharedProgramPhases.MissedAppointmentPaymentCompleted && (
            <MissedAppointmentPaymentCompletedCard />
         )}

         <motion.div variants={staggerVariants.child}>
            <Flex flexDir="column" gap={3}>
               <Flex gap={3}>
                  <SimpleMoodTracking
                     didTrackToday={moodTrackingData?.didTrackToday}
                     flex={1}
                  />
                  <HorizontalCard
                     title="Schedule next appointment"
                     flex={1}
                     hideBelow="lg"
                  >
                     <Skeleton
                        isLoaded={!isLoadingNextAppointment}
                        borderRadius="md"
                     >
                        <Text>
                           {nextAppointment?.title ?? 'As early as tomorrow'}
                        </Text>
                     </Skeleton>

                     <HorizontalCard.Button
                        aria-label="Track your mood"
                        iconName={
                           nextAppointment ? 'arrow-right' : 'chevron-down'
                        }
                        tooltipText={
                           nextAppointment && !appointmentCanBeScheduled
                              ? nextAppointment.error ||
                                'Please update your insurance info'
                              : undefined
                        }
                        isLoading={
                           isLoadingNextAppointment ||
                           (nextAppointment && isLocationsLoading)
                        }
                        isDisabled={
                           isLoadingNextAppointment ||
                           (nextAppointment &&
                              (isLocationsLoading ||
                                 !appointmentCanBeScheduled))
                        }
                        onClick={() => {
                           if (nextAppointment) {
                              const scheduleLink = generateSchedulingLink({
                                 encounterName: nextAppointment.templateName,
                                 locationId: location?.locationId,
                              });
                              push(scheduleLink);
                              return;
                           }

                           document
                              .getElementById(appointmentBankSectionId)
                              ?.scrollIntoView({
                                 behavior: 'smooth',
                                 block: 'start',
                              });
                        }}
                     />
                  </HorizontalCard>
               </Flex>

               {moodTrackingData?.sendWeeklyPause ? (
                  <WeeklyTrackAccordion />
               ) : null}
            </Flex>
         </motion.div>

         {patient?.currentProgram?.currentPhase.name ===
            IntakeProgramPhases.ExtendedLayoutUnlocked && (
            <>
               <Divider
                  borderBottom="2px solid"
                  borderColor="line.primary"
                  opacity={0.2}
               />

               <motion.div variants={staggerVariants.child}>
                  <InterestedInKetamine />
               </motion.div>
            </>
         )}

         <Flex
            as={motion.div}
            flexDir="column"
            gap={{ base: 4, lg: 6 }}
            variants={staggerVariants.child}
         >
            {Boolean(patient?.isPatientDischarged) && <PatientDischargedCard />}

            {isInKetamineProgram ? <StartKetamineExperienceCard /> : null}

            <InsuranceStatusCard />
         </Flex>

         {isLocked ? (
            <motion.div variants={staggerVariants.child}>
               <PatientLocked statuses={statuses} />
            </motion.div>
         ) : (
            <Flex flexDir="column" gap={{ base: 4, lg: 6 }}>
               {!!nextAppointment && (
                  <motion.div
                     initial={{ opacity: 0, height: 0 }}
                     animate={{ opacity: 1, height: 'auto' }}
                     transition={{ duration: 0.4 }}
                  >
                     <ScheduleNextAppointmentCard
                        appointment={nextAppointment}
                     />
                  </motion.div>
               )}

               <Flex
                  as={motion.div}
                  flexDir="column"
                  gap={4}
                  variants={staggerVariants.child}
               >
                  <DividerWithText text="Your upcoming appointments" />

                  <SkeletonWithLoader
                     loadingText="Loading upcoming appointments..."
                     minH={
                        appointmentsStatus === 'pending' ||
                        (upcomingAppointments && upcomingAppointments.length)
                           ? '56px'
                           : 0
                     }
                     borderRadius="12px"
                     isLoaded={appointmentsStatus === 'success'}
                  >
                     <UpcomingAppointments
                        appointments={upcomingAppointments}
                     />
                  </SkeletonWithLoader>
               </Flex>

               <motion.div
                  variants={staggerVariants.child}
                  id={appointmentBankSectionId}
               >
                  <AppointmentBank />
               </motion.div>
            </Flex>
         )}
      </Flex>
   );
};
