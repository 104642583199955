import { ExploreTabs } from '@/app/(private)/(side-nav)/explore/explore-tabs';

type RecommendationLink = {
   title: string;
   link: string;
   imageSrc: string;
   tag: string;
};

export const medicalConsultRecommendationLinks: RecommendationLink[] = [
   {
      title: 'Ketamine 101',
      link: 'https://helloinnerwell.com/reflections/ketamine-101',
      imageSrc: '/images/recommendations/ketamine-integration.webp',
      tag: 'Learn',
   },
   {
      title: 'How to Prepare for your Ketamine Experience',
      link: 'https://helloinnerwell.com/reflections/how-to-prepare-for-ketamine-experience',
      imageSrc: '/images/recommendations/how-to-prepare-ketamine.webp',
      tag: 'Learn',
   },
   {
      title: 'How to Find a Sitter',
      link: 'https://helloinnerwell.com/reflections/how-to-find-a-sitter',
      imageSrc: '/images/recommendations/how-to-find-a-sitter.webp',
      tag: 'Learn',
   },
   {
      title: 'How We Keep You Safe at Innerwell',
      link: 'https://helloinnerwell.com/reflections/how-we-keep-you-safe-at-innerwell ',
      imageSrc: '/images/recommendations/why-innerwell.webp',
      tag: 'Learn',
   },
];

export const afterConsultScheduleRecommendationLinks: RecommendationLink[] = [
   {
      title: 'Guide - Ketamine Integration',
      link: 'https://helloinnerwell.com/reflections/ketamine-integration-guide',
      imageSrc: '/images/recommendations/ketamine-integration.webp',
      tag: 'Learn',
   },
   {
      title: 'Why Innerwell?',
      link: 'https://helloinnerwell.com/why-innerwell',
      imageSrc: '/images/recommendations/why-innerwell.webp',
      tag: 'Learn',
   },
   {
      title: 'Breathe, Relax, Focus',
      link: `/explore?tab=${ExploreTabs.Meditate}`,
      imageSrc: '/images/recommendations/how-to-find-a-sitter.webp',
      tag: 'Meditate',
   },
   {
      title: 'Smile Swipe',
      link: `/explore?tab=${ExploreTabs.CardSwipe}`,
      imageSrc: '/images/recommendations/smile-swipe.webp',
      tag: 'Explore',
   },
];
