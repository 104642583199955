import { useMutation, useQueryClient } from '@tanstack/react-query';

import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';
import { handleSentryException } from '@/utils/sentry';

import { queryKeys } from '@/types/query-keys';
import { CdtContactConsent, PatientCdtsItem } from '@innerwell/dtos';
import { DateTime } from 'luxon';

export const useUpdateContactConsent = () => {
   const { toastSuccess, toastError } = useThemedToast();
   const queryClient = useQueryClient();

   return useMutation({
      mutationFn: () => {
         return webApiClient.contactConsent.confirmConsent();
      },

      onSuccess: () => {
         toastSuccess('Consent confirmed successfully.');

         queryClient.setQueryData(
            queryKeys.contactConsent,
            (data: PatientCdtsItem<CdtContactConsent> | undefined) => {
               if (!data) {
                  return undefined;
               }

               return {
                  ...data,
                  jsonBody: {
                     ...data.jsonBody,
                     'cdtf-confirmed': true,
                     'cdtf-date': DateTime.now().toISO(),
                  },
               } satisfies PatientCdtsItem<CdtContactConsent>;
            },
         );
      },
      onError: (error) => {
         handleSentryException(error);

         if (typeof error === 'string') {
            toastError(error);
         } else {
            toastError('An unknown error occurred. Please try again.');
         }
      },
   });
};
