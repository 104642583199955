import { Flex } from '@chakra-ui/react';
import { UpcomingAppointments } from '../Swipers/UpcomingAppointments';

import { motion } from 'framer-motion';
import { Card } from '../Cards/components/Card';
import { DividerWithText } from '../Dividers/DividerWithText';
import { SkeletonWithLoader } from '../Loaders/SkeletonWithLoader';
import { SimpleMoodTracking } from '../MoodTracking/SimpleMoodTracking';
import { WeeklyTrackAccordion } from '../MoodTracking/WeeklyTrackAccordion';
import { getStaggerVariants } from '@/utils/animation-utils';
import { useTodayMoodTracking } from '@/hooks/react-query/useTodayMoodTracking';
import useMyClinician from '@/hooks/react-query/useMyClinician';
import { formatClinician } from '@/utils/formatting';
import useMyPatient from '@/hooks/react-query/useMyPatient';
import useUpcomingAppointments from '@/hooks/react-query/useUpcomingAppointments';

const staggerVariants = getStaggerVariants({
   staggerChildren: 0.15,
});

export const MedicalConsultOnlyStep = () => {
   const { clinician } = useMyClinician();
   const formattedClinician = clinician ? formatClinician(clinician) : null;
   const { data: moodTrackingData } = useTodayMoodTracking();
   const { data: patient } = useMyPatient();
   const { appointments: upcomingAppointments, status: appointmentsStatus } =
      useUpcomingAppointments();

   return (
      <Flex
         as={motion.div}
         variants={staggerVariants.container}
         initial="hidden"
         animate="show"
         flexDir="column"
         gap={{ base: 4, lg: 6 }}
      >
         <motion.div variants={staggerVariants.child}>
            <Flex flexDir="column" gap={3}>
               <SimpleMoodTracking
                  didTrackToday={moodTrackingData?.didTrackToday}
                  flex={1}
               />

               {moodTrackingData?.sendWeeklyPause ? (
                  <WeeklyTrackAccordion />
               ) : null}
            </Flex>
         </motion.div>

         <motion.div variants={staggerVariants.child}>
            <Card>
               <Card.Image
                  imagePosition="center"
                  alt={formattedClinician?.fullName ?? 'Avatar'}
                  src={
                     formattedClinician?.imageUrl ??
                     '/images/ben-medrano-peach.jpg'
                  }
                  borderRadius={formattedClinician?.imageUrl ? '12px' : '50%'}
               />

               <Card.Title>Let’s take the next step, together.</Card.Title>
               {patient?.insurance?.isValid ? (
                  <Card.Text>
                     Your clinician has determined that you are a great fit for
                     a ketamine program at Innerwell.
                  </Card.Text>
               ) : (
                  <Card.Text>
                     Your clinician has determined that you are a great fit for
                     a ketamine program at Innerwell.
                     <br />
                     <br />
                     Use code <strong>UPGRADE175</strong> to save $175 on your
                     purchase.
                  </Card.Text>
               )}

               <Card.ButtonGroup>
                  <Card.ButtonLink
                     href={
                        patient?.insurance?.isValid
                           ? `/purchase/insurance/36`
                           : '/plans/choose-a-plan'
                     }
                  >
                     Purchase now
                  </Card.ButtonLink>
               </Card.ButtonGroup>
            </Card>
         </motion.div>

         <motion.div variants={staggerVariants.child}>
            <DividerWithText mb={4} text="Your upcoming appointments" />
            <SkeletonWithLoader
               loadingText="Loading upcoming appointments..."
               minH={
                  appointmentsStatus === 'pending' ||
                  (upcomingAppointments && upcomingAppointments.length)
                     ? '56px'
                     : 0
               }
               borderRadius="12px"
               isLoaded={appointmentsStatus !== 'pending'}
               mb={5}
            >
               <UpcomingAppointments appointments={upcomingAppointments} />
            </SkeletonWithLoader>
         </motion.div>
      </Flex>
   );
};
