import {
   Box,
   type BoxProps,
   Button,
   ButtonGroup,
   chakra,
   Flex,
   type FlexProps,
   Image,
   Modal,
   ModalBody,
   ModalContent,
   ModalHeader,
   ModalOverlay,
   Text,
   useBoolean,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { type AppointmentReservation } from '@innerwell/dtos';
import { useEffect, useRef, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { useAppointmentReservation } from '../hooks/useAppointmentReservation';

const avatarFallbackUrl =
   'https://app.helloinnerwell.com/_next/image?url=%2Fimages%2Fplaceholder-clinician.png&w=3840&q=100';

function HoldingYourAppointment({
   timerInMs,
   ...rest
}: {
   timerInMs: number;
} & BoxProps): React.ReactNode {
   return (
      <Box
         alignSelf="flex-start"
         bg="background.secondary"
         py={1}
         px={4}
         rounded="full"
         css={{
            fontVariantNumeric: 'tabular-nums',
         }}
         {...rest}
      >
         <Text fontSize="xs" color="text.secondary" fontWeight={500}>
            Holding your appointment:{' '}
            {Duration.fromMillis(timerInMs).toFormat('mm:ss')}
         </Text>
      </Box>
   );
}

export function AppointmentReservationCard({
   reservation,
   variant = 'default',
   showModalOnExpire = true,
   ...rest
}: {
   reservation: AppointmentReservation;
   variant?: 'default' | 'stretched';
   showModalOnExpire?: boolean;
} & FlexProps): React.ReactNode {
   const [isDeleted, setIsDeleted] = useState(false);
   const [isModalOpen, { on: openModal, off: closeModal }] = useBoolean(false);
   const {
      clinician,
      slotStartDatetime,
      slotEndDatetime,
      reservationExpiresAt,
   } = reservation;

   const clinicianFullName = `${clinician.name}, ${clinician.licenseTypes.join(', ')}`;

   const timeslot = `${format(slotStartDatetime, 'h:mm')} - ${format(slotEndDatetime, 'h:mm a')}`;
   const formattedDate = format(slotStartDatetime, 'EEEE, MMMM d, yyyy');
   const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);

   const [timerInMs, setTimerInMs] = useState(
      Math.max(
         0,
         DateTime.fromISO(reservationExpiresAt).diff(DateTime.now()).toMillis(),
      ),
   );

   const {
      get: appointmentReservation,
      set: updateAppointmentReservation,
      remove: deleteAppointmentReservation,
   } = useAppointmentReservation();

   useEffect(() => {
      timerIntervalRef.current = setInterval(() => {
         setTimerInMs((prev) => {
            if (prev <= 0) {
               return showModalOnExpire
                  ? 0
                  : Duration.fromObject({ minutes: 3 }).toMillis();
            }

            return prev - 1000;
         });
      }, 1000);

      return () => {
         if (timerIntervalRef.current) {
            clearInterval(timerIntervalRef.current);
         }
      };
   }, [showModalOnExpire]);

   useEffect(() => {
      if (isModalOpen) {
         return;
      }

      const secondsLeft = Math.floor(timerInMs / 1000);

      if (secondsLeft <= 0 && appointmentReservation && !showModalOnExpire) {
         setTimerInMs(Duration.fromObject({ minutes: 3 }).toMillis());
         updateAppointmentReservation({
            ...appointmentReservation,
            reservationExpiresAt: DateTime.now().plus({ minutes: 3 }).toISO(),
         });
         return;
      }

      if (secondsLeft <= 60 && showModalOnExpire) {
         openModal();
      }
   }, [
      timerInMs,
      isModalOpen,
      openModal,
      showModalOnExpire,
      appointmentReservation,
      updateAppointmentReservation,
   ]);

   if (isDeleted) {
      return null;
   }

   return (
      <>
         <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            closeOnEsc={false}
            closeOnOverlayClick={false}
         >
            <ModalOverlay />
            <ModalContent
               textAlign="center"
               mx={4}
               my="auto"
               boxShadow="0 5px 5px rgba(0, 0, 0, 0.2)"
               bgImage="linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%)"
               rounded="xl"
            >
               <ModalHeader pb={0}>
                  <Text fontSize="xl" fontWeight={500}>
                     Need More Time?
                  </Text>
               </ModalHeader>
               <ModalBody
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDir="column"
                  gap={4}
                  pb={6}
               >
                  <Text color="text.secondary" fontSize="xs">
                     Your appointment will be released soon. Would you like an
                     additional 3 minutes to hold your spot?
                  </Text>

                  <HoldingYourAppointment
                     alignSelf="center"
                     timerInMs={timerInMs}
                  />

                  <ButtonGroup orientation="vertical" w="full" mt={3}>
                     <Button
                        size="sm"
                        onClick={() => {
                           if (!appointmentReservation) {
                              return;
                           }

                           setTimerInMs(
                              Duration.fromObject({ minutes: 3 }).toMillis(),
                           );

                           closeModal();

                           updateAppointmentReservation({
                              ...appointmentReservation,
                              reservationExpiresAt: DateTime.now()
                                 .plus({ minutes: 3 })
                                 .toISO(),
                           });
                        }}
                     >
                        Yes, please
                     </Button>
                     <Button
                        size="sm"
                        variant="outline"
                        onClick={() => {
                           if (timerIntervalRef.current) {
                              clearInterval(timerIntervalRef.current);
                           }
                           closeModal();
                           setIsDeleted(true);
                           deleteAppointmentReservation();
                        }}
                     >
                        No, thank you
                     </Button>
                  </ButtonGroup>
               </ModalBody>
            </ModalContent>
         </Modal>
         <Flex
            position="relative"
            color="text.primary"
            w="full"
            textAlign="left"
            py={4}
            px={variant === 'default' ? { base: 3, lg: 4 } : 3}
            borderRadius={variant === 'default' ? 'lg' : 'none'}
            overflow="hidden"
            background="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
            boxShadow={
               variant === 'default'
                  ? '0px 3px 3px 0px rgba(0, 0, 0, 0.05)'
                  : 'none'
            }
            borderBottom={
               variant === 'default' ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'
            }
            zIndex={1}
            flexDir="column"
            gap={1}
            {...rest}
         >
            <Flex
               gap={4}
               justifyContent={{
                  base: 'flex-start',
                  lg: variant === 'default' ? 'flex-start' : 'center',
               }}
               alignItems={
                  variant === 'default'
                     ? 'flex-start'
                     : { base: 'flex-start', lg: 'center' }
               }
            >
               <Image
                  src={clinician.avatarUrl ?? avatarFallbackUrl}
                  rounded="full"
                  boxSize="4.125rem"
                  flexShrink={0}
               />
               <Flex flexDir="column" gap={1.5}>
                  <Text fontWeight={600} lineHeight={1.3} pr={5}>
                     {`Your appointment with ${clinicianFullName}`}
                  </Text>
                  <Text>
                     {variant === 'default' ? (
                        <>
                           {formattedDate}
                           <br />
                           {timeslot}
                        </>
                     ) : (
                        <>
                           {formattedDate}
                           <chakra.br hideFrom="lg" />
                           <chakra.span hideBelow="lg"> at </chakra.span>
                           {timeslot}
                        </>
                     )}
                  </Text>
                  {variant === 'stretched' ? (
                     <HoldingYourAppointment timerInMs={timerInMs} />
                  ) : null}
               </Flex>
            </Flex>
            {variant === 'default' ? (
               <HoldingYourAppointment timerInMs={timerInMs} />
            ) : null}
         </Flex>
      </>
   );
}
