import { DateTime } from 'luxon';

import { useProgramEncounters } from './useProgramEncounters';

export const useNextProgramEncountersToSchedule = () => {
   const { programEncounters, ...rest } = useProgramEncounters();

   const nextAppointments = programEncounters
      ?.filter((appointment) => {
         return (
            appointment.usedCount < appointment.purchasedCount &&
            Boolean(appointment.schedulingBounds?.earliestStartDate)
         );
      })
      .sort((a, b) => {
         return (
            DateTime.fromISO(a.schedulingBounds?.earliestStartDate as string)
               .toJSDate()
               .getTime() -
            DateTime.fromISO(b.schedulingBounds?.earliestStartDate as string)
               .toJSDate()
               .getTime()
         );
      });

   return {
      ...rest,
      nextAppointments,
   };
};
