'use client';
import { type StateAbbrValue, US_STATES } from '@innerwell/us-states';
import {
   type ChakraStylesConfig,
   type GroupBase,
   type Props as SelectProps,
   Select,
   type SingleValue,
   type SelectInstance,
} from 'chakra-react-select';
import { type ArrayElementType } from '@innerwell/utils';
import { forwardRef, type Ref } from 'react';

const DROPDOWN_OPTIONS = US_STATES.map((state) => ({
   value: state.abbr,
   label: state.title,
}));

type Option = ArrayElementType<typeof DROPDOWN_OPTIONS>;

type Props = {
   isDisabled?: boolean;
   // Use false to remove the label
   label?: string | false;
   // @TODO: this is a shitty solution to display that only available states are shown
   additionalText?: React.ReactNode;
   value?: StateAbbrValue;
   onChange?: (value: StateAbbrValue | null) => void;
} & Omit<SelectProps<Option, false, GroupBase<Option>>, 'value' | 'onChange'>;

function StateSelectWithRef(
   { onChange, options = DROPDOWN_OPTIONS, value, ...selectProps }: Props,
   ref: Ref<SelectInstance<Option, false, GroupBase<Option>>>,
): React.ReactNode {
   const chakraStyles: ChakraStylesConfig<Option> = {
      option: (provided) => ({
         ...provided,
         fontSize: '1rem',
      }),
      menuList: (provided) => ({
         ...provided,
         mb: 3,
         maxHeight: '300px',
      }),
   };

   return (
      <Select<Option, false, GroupBase<Option>>
         ref={ref}
         instanceId="innerwell"
         size="lg"
         classNamePrefix="chakra-react-select"
         chakraStyles={chakraStyles}
         styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
         }}
         menuPortalTarget={global.document.body}
         selectedOptionColor="gray"
         formatOptionLabel={(option) => {
            return option.label;
         }}
         value={DROPDOWN_OPTIONS.find((opt) => opt.value === value)}
         onChange={(newValue) => {
            if (!Array.isArray(newValue) && newValue) {
               const singleValue = newValue as SingleValue<Option>;
               onChange?.(singleValue?.value ?? null);
            }
         }}
         filterOption={(option, inputValue: string) => {
            return option.label
               .toLowerCase()
               .includes(inputValue.toLowerCase());
         }}
         useBasicStyles
         placeholder="Choose state"
         {...selectProps}
         options={options}
      />
   );
}

export const StateSelect = forwardRef(StateSelectWithRef);
