import { Box, type BoxProps, Text, useBoolean } from '@chakra-ui/react';

import BoxedSandModal from '@/components/Modals/BoxedSandModal';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';
import { INNERWELL_SUPPORT_EMAIL } from '@/utils/consts';
import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import { CustomerIOEvents, IntakeProgramPhases } from '@innerwell/dtos';
import { Card } from '@/components/Cards/components/Card';
import { getAppSource } from '@/utils/analytics-utils';
import { usePatientProgram } from '@/contexts/patient-program-context';
import { isIntakeProgramAfterOrEqual } from '@innerwell/utils';

import { Tooltip } from '@/components/Tooltip/Tooltip';

type Props = BoxProps;

const UploadIdStep = (props: Props) => {
   const { data: session } = useSession();

   const {
      programPhase: { phase },
   } = usePatientProgram();

   const [isModalOpen, { on: openModal, off: closeModal }] = useBoolean();

   const uploadEnabled = isIntakeProgramAfterOrEqual(
      phase,
      IntakeProgramPhases.MedicalIntakeAccepted,
   );

   return (
      <Box {...props}>
         <Card>
            <Card.Title>{`Let's get you verified`}</Card.Title>
            <Card.Text>
               {`We'd like to confirm your identity. Please upload an image of
               your driver's license or passport.`}
            </Card.Text>
            <Card.ButtonGroup>
               <Tooltip
                  label="Waiting for Medical Intake approval"
                  isDisabled={uploadEnabled}
               >
                  <Card.ButtonLink
                     href="/user-consent/document-upload"
                     disabled={!uploadEnabled}
                     onClick={() => {
                        if (session) {
                           webApiClient.customerio.track({
                              body: {
                                 id: session['cognito:username'],
                                 name: CustomerIOEvents.UploadIdStarted,
                                 app_source: getAppSource(),
                              },
                           });
                        }
                     }}
                  >
                     Upload
                  </Card.ButtonLink>
               </Tooltip>
               <Card.Button variant="link" onClick={openModal}>
                  I need help
               </Card.Button>
            </Card.ButtonGroup>
            <Card.Image
               src="/images/medical-consult-woman.svg"
               alt="Card image"
            />
         </Card>

         <BoxedSandModal
            isOpen={isModalOpen}
            onClose={closeModal}
            closeOnOverlayClick
            closeOnEsc
         >
            <BoxedSandModal.Heading>
               ID Upload Assistance
            </BoxedSandModal.Heading>
            <Text color="text.secondary" opacity={0.7} fontWeight={500}>
               To complete your verification, please upload your ID. If you
               experience issues with the form, you can also email your document
               to our care@helloinnerwell.com or contact support for further
               assistance.
            </Text>

            <BoxedSandModal.ButtonGroup w="full">
               <NextLinkButton
                  href={`mailto:${INNERWELL_SUPPORT_EMAIL}`}
                  size={{ base: 'sm', lg: 'md' }}
                  w="full"
               >
                  Send email
               </NextLinkButton>
               <NextLinkButton
                  href="/support"
                  size={{ base: 'sm', lg: 'md' }}
                  w="full"
                  variant="outline"
               >
                  Contact support
               </NextLinkButton>
            </BoxedSandModal.ButtonGroup>
         </BoxedSandModal>
      </Box>
   );
};

export default UploadIdStep;
