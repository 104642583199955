import { webApiClient } from '@/api-client/apiClient';
import { queryKeys } from '@/types/query-keys';
import { type CreateAppointmentTimetapDto } from '@innerwell/dtos';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCreateAppointment = () => {
   const queryClient = useQueryClient();

   return useMutation({
      mutationFn: async (data: CreateAppointmentTimetapDto) => {
         const response = await webApiClient.appointments.createTimetap({
            body: data,
         });

         return response.body;
      },
      onSuccess: () => {
         queryClient.resetQueries({
            queryKey: queryKeys.upcomingAppointments,
         });
         queryClient.resetQueries({
            queryKey: queryKeys.isAppointmentReschedulableKey,
         });
         queryClient.resetQueries({
            queryKey: queryKeys.appointmentBank,
         });
      },
   });
};
